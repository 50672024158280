.red { color: #F52F22; }
.red-1 { color: #FFF0EE; }
.red-2 { color: #F5DBD9; }
.red-3 { color: #F8B7B6; }
.red-4 { color: #F89997; }
.red-5 { color: #F76F6C; }
.red-6 { color: #F54F47; }
.red-7 { color: #F52F22; }
.red-8 { color: #e12200; }
.red-9 { color: #C92100; }
.red-10 { color: #A32100; }

.bg-red { background-color: #F52F22; }
.bg-red-1 { background-color: #FFF0EE; }
.bg-red-2 { background-color: #F5DBD9; }
.bg-red-3 { background-color: #F8B7B6; }
.bg-red-4 { background-color: #F89997; }
.bg-red-5 { background-color: #F76F6C; }
.bg-red-6 { background-color: #F54F47; }
.bg-red-7 { background-color: #F52F22; }
.bg-red-8 { background-color: #e12200; }
.bg-red-9 { background-color: #C92100; }
.bg-red-10 { background-color: #A32100; }

.pink { color: #F1428A; }
.pink-1 { color: #FFEBF5; }
.pink-2 { color: #FBC1DA; }
.pink-3 { color: #F897BF; }
.pink-4 { color: #F46CA5; }
.pink-5 { color: #F1428A; }
.pink-6 { color: #ED186F; }
.pink-7 { color: #D91468; }
.pink-8 { color: #C41261; }
.pink-9 { color: #B0105B; }
.pink-10 { color: #9B0D54; }

.bg-pink { background-color: #F1428A; }
.bg-pink-1 { background-color: #FBC1DA; }
.bg-pink-2 { background-color: #F5DBD9; }
.bg-pink-3 { background-color: #F897BF; }
.bg-pink-4 { background-color: #F46CA5; }
.bg-pink-5 { background-color: #F1428A; }
.bg-pink-6 { background-color: #ED186F; }
.bg-pink-7 { background-color: #D91468; }
.bg-pink-8 { background-color: #C41261; }
.bg-pink-9 { background-color: #B0105B; }
.bg-pink-10 { background-color: #9B0D54; }

.purple { color: #781DA0; }
.purple-1 { color: #F3E6FF; }
.purple-2 { color: #E1C9F1; }
.purple-3 { color: #D0ACE4; }
.purple-4 { color: #BE90D6; }
.purple-5 { color: #AD73C8; }
.purple-6 { color: #9B56BB; }
.purple-7 { color: #8939AD; }
.purple-8 { color: #781DA0; }
.purple-9 { color: #660092; }
.purple-10 { color: #4D007A; }

.bg-purple { background-color: #781DA0; }
.bg-purple-1 { background-color: #F3E6FF; }
.bg-purple-2 { background-color: #E1C9F1; }
.bg-purple-3 { background-color: #D0ACE4; }
.bg-purple-4 { background-color: #BE90D6; }
.bg-purple-5 { background-color: #AD73C8; }
.bg-purple-6 { background-color: #9B56BB; }
.bg-purple-7 { background-color: #8939AD; }
.bg-purple-8 { background-color: #781DA0; }
.bg-purple-9 { background-color: #660092; }
.bg-purple-10 { background-color: #4D007A; }

.ultramarine { color: #343DAC; }
.ultramarine-1 { color: #EBF0FF; }
.ultramarine-2 { color: #D1D6F3; }
.ultramarine-3 { color: #B7BDE7; }
.ultramarine-4 { color: #9DA3DB; }
.ultramarine-5 { color: #838ACF; }
.ultramarine-6 { color: #6870C4; }
.ultramarine-7 { color: #4E56B8; }
.ultramarine-8 { color: #343DAC; }
.ultramarine-9 { color: #1A23A0; }
.ultramarine-10 { color: #0F1E82; }

.bg-ultramarine { background-color: #343DAC; }
.bg-ultramarine-1 { background-color: #EBF0FF; }
.bg-ultramarine-2 { background-color: #D1D6F3; }
.bg-ultramarine-3 { background-color: #B7BDE7; }
.bg-ultramarine-4 { background-color: #9DA3DB; }
.bg-ultramarine-5 { background-color: #838ACF; }
.bg-ultramarine-6 { background-color: #6870C4; }
.bg-ultramarine-7 { background-color: #4E56B8; }
.bg-ultramarine-8 { background-color: #343DAC; }
.bg-ultramarine-9 { background-color: #1A23A0; }
.bg-ultramarine-10 { background-color: #0F1E82; }

.blue { color: #0065AB; }
.blue-1 { color: #E1F1F6; }
.blue-2 { color: #C5E5EF; }
.blue-3 { color: #A6D8E7; }
.blue-4 { color: #89CBDF; }
.blue-5 { color: #49AFD9; }
.blue-6 { color: #0095D3; }
.blue-7 { color: #0079B8; }
.blue-8 { color: #0065AB; }
.blue-9 { color: #004D8A; }
.blue-10 { color: #003D79; }

.bg-blue { background-color: #0065AB; }
.bg-blue-1 { background-color: #E1F1F6; }
.bg-blue-2 { background-color: #C5E5EF; }
.bg-blue-3 { background-color: #A6D8E7; }
.bg-blue-4 { background-color: #89CBDF; }
.bg-blue-5 { background-color: #49AFD9; }
.bg-blue-6 { background-color: #0095D3; }
.bg-blue-7 { background-color: #0079B8; }
.bg-blue-8 { background-color: #0065AB; }
.bg-blue-9 { background-color: #004D8A; }
.bg-blue-10 { background-color: #003D79; }

.cyan { color: #00B7D6; }
.cyan-1 { color: #CCFBFF; }
.cyan-2 { color: #A3EDF6; }
.cyan-3 { color: #6DDBEB; }
.cyan-4 { color: #36C9E1; }
.cyan-5 { color: #00B7D6; }
.cyan-6 { color: #009CBF; }
.cyan-7 { color: #0081A7; }
.cyan-8 { color: #006690; }
.cyan-9 { color: #005680; }
.cyan-10 { color: #004A70; }

.bg-cyan { background-color: #00B7D6; }
.bg-cyan-1 { background-color: #CCFBFF; }
.bg-cyan-2 { background-color: #A3EDF6; }
.bg-cyan-3 { background-color: #6DDBEB; }
.bg-cyan-4 { background-color: #36C9E1; }
.bg-cyan-5 { background-color: #00B7D6; }
.bg-cyan-6 { background-color: #009CBF; }
.bg-cyan-7 { background-color: #0081A7; }
.bg-cyan-8 { background-color: #006690; }
.bg-cyan-9 { background-color: #005680; }
.bg-cyan-10 { background-color: #004A70; }

.teal { color: #00968B; }
.teal-1 { color: #DEFFF9; }
.teal-2 { color: #A7F4E9; }
.teal-3 { color: #6FEAD9; }
.teal-4 { color: #38DFC8; }
.teal-5 { color: #00D4B8; }
.teal-6 { color: #00BFA9; }
.teal-7 { color: #00AB9A; }
.teal-8 { color: #00968B; }
.teal-9 { color: #007E7A; }
.teal-10 { color: #006668; }

.bg-teal { background-color: #00968B; }
.bg-teal-1 { background-color: #DEFFF9; }
.bg-teal-2 { background-color: #A7F4E9; }
.bg-teal-3 { background-color: #6FEAD9; }
.bg-teal-4 { background-color: #38DFC8; }
.bg-teal-5 { background-color: #00D4B8; }
.bg-teal-6 { background-color: #00BFA9; }
.bg-teal-7 { background-color: #00AB9A; }
.bg-teal-8 { background-color: #00968B; }
.bg-teal-9 { background-color: #007E7A; }
.bg-teal-10 { background-color: #006668; }

.green { color: #48960C; }
.green-1 { color: #DFF0D0; }
.green-2 { color: #C7E59C; }
.green-3 { color: #AADB1E; }
.green-4 { color: #85C81A; }
.green-5 { color: #60B515; }
.green-6 { color: #62A420; }
.green-7 { color: #48960C; }
.green-8 { color: #318700; }
.green-9 { color: #266900; }
.green-10 { color: #1D5100; }

.bg-green { background-color: #48960C; }
.bg-green-1 { background-color: #DFF0D0; }
.bg-green-2 { background-color: #C7E59C; }
.bg-green-3 { background-color: #AADB1E; }
.bg-green-4 { background-color: #85C81A; }
.bg-green-5 { background-color: #60B515; }
.bg-green-6 { background-color: #62A420; }
.bg-green-7 { background-color: #48960C; }
.bg-green-8 { background-color: #318700; }
.bg-green-9 { background-color: #266900; }
.bg-green-10 { background-color: #1D5100; }

.yellow { color: #FFDC0B; }
.yellow-1 { color: #FFFCE8; }
.yellow-2 { color: #FEF3B5; }
.yellow-3 { color: #FFE860; }
.yellow-4 { color: #FFDC0B; }
.yellow-5 { color: #FDD006; }
.yellow-6 { color: #FAC400; }
.yellow-7 { color: #EDB200; }
.yellow-8 { color: #DFA100; }
.yellow-9 { color: #D28F00; }
.yellow-10 { color: #C47D00; }

.bg-yellow { background-color: #FFDC0B; }
.bg-yellow-1 { background-color: #FFFCE8; }
.bg-yellow-2 { background-color: #FEF3B5; }
.bg-yellow-3 { background-color: #FFE860; }
.bg-yellow-4 { background-color: #FFDC0B; }
.bg-yellow-5 { background-color: #FDD006; }
.bg-yellow-6 { background-color: #FAC400; }
.bg-yellow-7 { background-color: #EDB200; }
.bg-yellow-8 { background-color: #DFA100; }
.bg-yellow-9 { background-color: #D28F00; }
.bg-yellow-10 { background-color: #C47D00; }

.orange { color: #F57600; }
.orange-1 { color: #FFE5C9; }
.orange-2 { color: #FFCD97; }
.orange-3 { color: #FFB565; }
.orange-4 { color: #FF9C32; }
.orange-5 { color: #FF8400; }
.orange-6 { color: #F57600; }
.orange-7 { color: #E46C00; }
.orange-8 { color: #D36000; }
.orange-9 { color: #C25400; }
.orange-10 { color: #AA4500; }

.bg-orange { background-color: #F57600; }
.bg-orange-1 { background-color: #FFE5C9; }
.bg-orange-2 { background-color: #FFCD97; }
.bg-orange-3 { background-color: #FFB565; }
.bg-orange-4 { background-color: #FF9C32; }
.bg-orange-5 { background-color: #FF8400; }
.bg-orange-6 { background-color: #F57600; }
.bg-orange-7 { background-color: #E46C00; }
.bg-orange-8 { background-color: #D36000; }
.bg-orange-9 { background-color: #C25400; }
.bg-orange-10 { background-color: #AA4500; }

.red-orange { color: #EE4A08; }
.red-orange-1 { color: #FFE5DC; }
.red-orange-2 { color: #FFCCB5; }
.red-orange-3 { color: #FFB38F; }
.red-orange-4 { color: #FF9A69; }
.red-orange-5 { color: #FF8142; }
.red-orange-6 { color: #FF681C; }
.red-orange-7 { color: #FF5500; }
.red-orange-8 { color: #EE4A08; }
.red-orange-9 { color: #DE400F; }
.red-orange-10 { color: #CD3517; }

.bg-red-orange { background-color: #EE4A08; }
.bg-red-orange-1 { background-color: #FFE5DC; }
.bg-red-orange-2 { background-color: #FFCCB5; }
.bg-red-orange-3 { background-color: #FFB38F; }
.bg-red-orange-4 { background-color: #FF9A69; }
.bg-red-orange-5 { background-color: #FF8142; }
.bg-red-orange-6 { background-color: #FF681C; }
.bg-red-orange-7 { background-color: #FF5500; }
.bg-red-orange-8 { background-color: #EE4A08; }
.bg-red-orange-9 { background-color: #DE400F; }
.bg-red-orange-10 { background-color: #CD3517; }

.warm-grey { color: #80746D; }
.warm-grey-1 { color: #FAF9F5; }
.warm-grey-2 { color: #F4F1E6; }
.warm-grey-3 { color: #E3DDD1; }
.warm-grey-4 { color: #CFC8BD; }
.warm-grey-5 { color: #BBB3A9; }
.warm-grey-6 { color: #A89E95; }
.warm-grey-7 { color: #948981; }
.warm-grey-8 { color: #80746D; }
.warm-grey-9 { color: #6C5F59; }
.warm-grey-10 { color: #5B4D47; }

.bg-warm-grey { background-color: #80746D; }
.bg-warm-grey-1 { background-color: #FAF9F5; }
.bg-warm-grey-2 { background-color: #F4F1E6; }
.bg-warm-grey-3 { background-color: #E3DDD1; }
.bg-warm-grey-4 { background-color: #CFC8BD; }
.bg-warm-grey-5 { background-color: #BBB3A9; }
.bg-warm-grey-6 { background-color: #A89E95; }
.bg-warm-grey-7 { background-color: #948981; }
.bg-warm-grey-8 { background-color: #80746D; }
.bg-warm-grey-9 { background-color: #6C5F59; }
.bg-warm-grey-10 { background-color: #5B4D47; }

.neutral-grey { color: #737373; }
.neutral-grey-1 { color: #FAFAFA; }
.neutral-grey-2 { color: #F2F2F2; }
.neutral-grey-3 { color: #EEEEEE; }
.neutral-grey-4 { color: #DDDDDD; }
.neutral-grey-5 { color: #CCCCCC; }
.neutral-grey-6 { color: #9A9A9A; }
.neutral-grey-7 { color: #737373; }
.neutral-grey-8 { color: #565656; }
.neutral-grey-9 { color: #444444; }
.neutral-grey-10 { color: #313131; }

.bg-neutral-grey { background-color: #737373; }
.bg-neutral-grey-1 { background-color: #FAFAFA; }
.bg-neutral-grey-2 { background-color: #F2F2F2; }
.bg-neutral-grey-3 { background-color: #EEEEEE; }
.bg-neutral-grey-4 { background-color: #DDDDDD; }
.bg-neutral-grey-5 { background-color: #CCCCCC; }
.bg-neutral-grey-6 { background-color: #9A9A9A; }
.bg-neutral-grey-7 { background-color: #737373; }
.bg-neutral-grey-8 { background-color: #565656; }
.bg-neutral-grey-9 { background-color: #444444; }
.bg-neutral-grey-10 { background-color: #313131; }

.cool-grey { color: #61717D; }
.cool-grey-1 { color: #919FA8; }
.cool-grey-2 { color: #A9B6BE; }
.cool-grey-3 { color: #C1CDD4; }
.cool-grey-4 { color: #D9E4EA; }
.cool-grey-5 { color: #F3F6FA; }
.cool-grey-6 { color: #798893; }
.cool-grey-7 { color: #61717D; }
.cool-grey-8 { color: #495A67; }
.cool-grey-9 { color: #314351; }
.cool-grey-10 { color: #25333D; }

.bg-cool-grey { background-color: #61717D; }
.bg-cool-grey-1 { background-color: #919FA8; }
.bg-cool-grey-2 { background-color: #A9B6BE; }
.bg-cool-grey-3 { background-color: #C1CDD4; }
.bg-cool-grey-4 { background-color: #D9E4EA; }
.bg-cool-grey-5 { background-color: #F3F6FA; }
.bg-cool-grey-6 { background-color: #798893; }
.bg-cool-grey-7 { background-color: #61717D; }
.bg-cool-grey-8 { background-color: #495A67; }
.bg-cool-grey-9 { background-color: #314351; }
.bg-cool-grey-10 { background-color: #25333D; }
