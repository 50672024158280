.vertical-top {
  position: relative;

  div {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }

  span {
    position: absolute;
    top: 0;
  }
}

.vertical-center {
  position: relative;

  div {
    //height: 10%;
    margin: auto 0;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  span {
    margin: auto 0;
    position: absolute;
    top: 0;
    bottom: 0;
  }
}

.vertical-bottom {
  position: relative;

  div {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }

  span {
    position: absolute;
    bottom: 0;
  }
}

.container-center {
  //height: 300px;
  display: -webkit-flex;
  display:         flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}