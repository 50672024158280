#footer {
    .footer-container {
        display:flex;
        flex-direction:column;
        justify-content:center;
        @media (min-width:1920px){display:flex; flex-direction:row; justify-content:space-between;}
        @media (min-width:1280px) and (max-width:1919px){display:flex; flex-direction:row; justify-content:space-between;}
        @media (min-width:768px) and (max-width:1279px){display:flex; flex-direction:row; justify-content:space-between;}
        @media (max-width:767px){display:flex;flex-direction:column; justify-content:center;}
    }
}