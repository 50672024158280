#members {
    .members-container {
        position:relative;
        width:100%;
        height:100%;
        background:#212529;
        font-family:Spoqa Han Sans;
        letter-spacing:-0.25px;
        padding-top:8%;
        padding-bottom:50px;
        @media (min-width:1920px){padding-top:8%;}
        @media (min-width:1280px) and (max-width:1919px){padding-top:8%;}
        @media (min-width:768px) and (max-width:1279px){padding-top:15%;}
        @media (max-width:767px){padding-top:15%;}
    }

    .item-container {
        width:100%;
        display:flex;
        flex-direction:column;
        align-items:flex-start;
        flex-wrap:wrap;
        padding:0px;
        margin-top:20px;
        @media (min-width:1920px){width:100%; display:flex; flex-direction:row; justify-content:flex-start; align-items:center; padding:0px 7%; margin-top:70px;}
        @media (min-width:1280px) and (max-width:1919px){width:100%; display:flex; flex-direction:row; justify-content:flex-start; align-items:center; padding:0px 7%; margin-top:70px;}
        @media (min-width:768px) and (max-width:1279px){width:100%; display:flex; flex-direction:row; justify-content:flex-start; align-items:center; padding:0px 5%; margin-top:50px;}
        @media (max-width:767px){width:100%; display:flex; flex-direction:column; align-items:center; padding:0px; margin-top:20px;}
    }

    .member-img-container {
        position:relative;
        display:flex;
        flex-direction:column;
        justify-content:space-between;
        align-items:center;
        margin:20px 30px;
    }

    .member-img {
        display:block;
        width:264px;
        height:264px;
        border-radius:50%;
    }

    .overlay {
        opacity:0;
        position:absolute; 
        top:0; 
        width:264px;
        height:264px;
        border-radius:50%;
        background:rgba(110,38,19,1); 
        display:flex; 
        justify-content:center;
        align-items:center;
        transition:all 0.2s ease-out;
    }

    .member-img-container:hover .overlay {
        opacity:0.75 !important;
        transition:all 0.2s ease-in !important;
    }
}