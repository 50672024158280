#history {
    .history-container {
        position:relative;
        width:100%;
        height:100%;
        background:#212529;
        font-family:Spoqa Han Sans; 
        letter-spacing:-0.25px;
        padding-top:15%;
        padding-bottom:100px;
        @media (min-width:1920px){padding-top:8%; padding-bottom:100px;}
        @media (min-width:1280px) and (max-width:1919px){padding-top:8%; padding-bottom:100px;}
        @media (min-width:768px) and (max-width:1279px){padding-top:15%; padding-bottom:100px;}
        @media (max-width:767px){padding-top:15%; padding-bottom:100px;}
    }

    .item-container {
        display:flex;
        justify-content:center;
        margin-top:5%;
        padding:0px 35px;
        @media (min-width:1920px){margin-top:5%; padding:0px;}
        @media (min-width:1280px) and (max-width:1919px){margin-top:5%; padding:0px;}
        @media (min-width:768px) and (max-width:1279px){margin-top:15%; padding:0px;}
        @media (max-width:767px){margin-top:5%; padding:0px 35px;}
    }

    .item-margin{
        display:flex;
        flex-direction:column;
        margin-left:-5.5px;
        margin-top:10%;
        @media (min-width:1920px){margin-top:5%;}
        @media (min-width:1280px) and (max-width:1919px){margin-top:5%;}
        @media (min-width:768px) and (max-width:1279px){margin-top:8%;}
        @media (max-width:767px){margin-top:10%;}
    }

}