.width-full { width: 100%; }
.width-half { width: 50%; }

.width-5 { width: 5px; }
.width-10 { width: 10px; }
.width-15 { width: 15px; }
.width-20 { width: 20px; }
.width-25 { width: 25px; }
.width-30 { width: 30px; }
.width-35 { width: 35px; }
.width-40 { width: 40px; }
.width-45 { width: 45px; }
.width-50 { width: 50px; }
.width-55 { width: 55px; }
.width-60 { width: 60px; }
.width-65 { width: 65px; }
.width-70 { width: 70px; }
.width-75 { width: 75px; }
.width-80 { width: 80px; }
.width-85 { width: 85px; }
.width-90 { width: 90px; }
.width-95 { width: 95px; }
.width-100 { width: 100px; }
.width-105 { width: 105px; }
.width-110 { width: 110px; }
.width-115 { width: 115px; }
.width-120 { width: 120px; }
.width-125 { width: 125px; }
.width-130 { width: 130px; }
.width-135 { width: 135px; }
.width-140 { width: 140px; }
.width-145 { width: 145px; }
.width-150 { width: 150px; }
.width-155 { width: 155px; }
.width-160 { width: 160px; }
.width-165 { width: 165px; }
.width-170 { width: 170px; }
.width-175 { width: 175px; }
.width-180 { width: 180px; }
.width-185 { width: 185px; }
.width-190 { width: 190px; }
.width-195 { width: 195px; }
.width-200 { width: 200px; }
.width-250 { width: 250px; }
.width-300 { width: 300px; }
.width-350 { width: 350px; }
.width-400 { width: 400px; }
.width-450 { width: 450px; }
.width-500 { width: 500px; }

.min-width-5 { min-width: 5px; }
.min-width-10 { min-width: 10px; }
.min-width-15 { min-width: 15px; }
.min-width-20 { min-width: 20px; }
.min-width-25 { min-width: 25px; }
.min-width-30 { min-width: 30px; }
.min-width-35 { min-width: 35px; }
.min-width-40 { min-width: 40px; }
.min-width-45 { min-width: 45px; }
.min-width-50 { min-width: 50px; }
.min-width-55 { min-width: 55px; }
.min-width-60 { min-width: 60px; }
.min-width-65 { min-width: 65px; }
.min-width-70 { min-width: 70px; }
.min-width-75 { min-width: 75px; }
.min-width-80 { min-width: 80px; }
.min-width-85 { min-width: 85px; }
.min-width-90 { min-width: 90px; }
.min-width-95 { min-width: 95px; }
.min-width-100 { min-width: 100px; }
.min-width-105 { min-width: 105px; }
.min-width-110 { min-width: 110px; }
.min-width-115 { min-width: 115px; }
.min-width-120 { min-width: 120px; }
.min-width-125 { min-width: 125px; }
.min-width-130 { min-width: 130px; }
.min-width-135 { min-width: 135px; }
.min-width-140 { min-width: 140px; }
.min-width-145 { min-width: 145px; }
.min-width-150 { min-width: 150px; }
.min-width-155 { min-width: 155px; }
.min-width-160 { min-width: 160px; }
.min-width-165 { min-width: 165px; }
.min-width-170 { min-width: 170px; }
.min-width-175 { min-width: 175px; }
.min-width-180 { min-width: 180px; }
.min-width-185 { min-width: 185px; }
.min-width-190 { min-width: 190px; }
.min-width-195 { min-width: 195px; }
.min-width-200 { min-width: 200px; }
.min-width-250 { min-width: 250px; }
.min-width-300 { min-width: 300px; }
.min-width-350 { min-width: 350px; }
.min-width-400 { min-width: 400px; }
.min-width-450 { min-width: 450px; }
.min-width-500 { min-width: 500px; }

.max-width-5 { max-width: 5px; }
.max-width-10 { max-width: 10px; }
.max-width-15 { max-width: 15px; }
.max-width-20 { max-width: 20px; }
.max-width-25 { max-width: 25px; }
.max-width-30 { max-width: 30px; }
.max-width-35 { max-width: 35px; }
.max-width-40 { max-width: 40px; }
.max-width-45 { max-width: 45px; }
.max-width-50 { max-width: 50px; }
.max-width-55 { max-width: 55px; }
.max-width-60 { max-width: 60px; }
.max-width-65 { max-width: 65px; }
.max-width-70 { max-width: 70px; }
.max-width-75 { max-width: 75px; }
.max-width-80 { max-width: 80px; }
.max-width-85 { max-width: 85px; }
.max-width-90 { max-width: 90px; }
.max-width-95 { max-width: 95px; }
.max-width-100 { max-width: 100px; }
.max-width-105 { max-width: 105px; }
.max-width-110 { max-width: 110px; }
.max-width-115 { max-width: 115px; }
.max-width-120 { max-width: 120px; }
.max-width-125 { max-width: 125px; }
.max-width-130 { max-width: 130px; }
.max-width-135 { max-width: 135px; }
.max-width-140 { max-width: 140px; }
.max-width-145 { max-width: 145px; }
.max-width-150 { max-width: 150px; }
.max-width-155 { max-width: 155px; }
.max-width-160 { max-width: 160px; }
.max-width-165 { max-width: 165px; }
.max-width-170 { max-width: 170px; }
.max-width-175 { max-width: 175px; }
.max-width-180 { max-width: 180px; }
.max-width-185 { max-width: 185px; }
.max-width-190 { max-width: 190px; }
.max-width-195 { max-width: 195px; }
.max-width-200 { max-width: 200px; }
.max-width-250 { max-width: 250px; }
.max-width-300 { max-width: 300px; }
.max-width-350 { max-width: 350px; }
.max-width-400 { max-width: 400px; }
.max-width-450 { max-width: 450px; }
.max-width-500 { max-width: 500px; }

.width-login {
  width: 350px;
  max-width: 400px;
}

.width-login-ad {
  //width: 100%;
  max-width: 200px;
  margin: 0 auto;
}