//@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500');
//@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
//
//// Core
//@import 'core/variables';
//@import '~uglsoft-common-v1-frontend-theme/_theming';
//
//// Easyear
//@import 'frontend-web';
//
//// 아래 코드와 부트스트랩 순서때문에 타이포그래피 코드가 적용 안되는 문제가 발생하여 아래 코드를 삽입함
//// 향후에 아래 코드는 지우고 순서를 제대로 기술해야할 듯함.
////@import 'core/reset';


//home-typography
// @import './easyear/theme-juice/home-typography';

#home {
    html {
        font-size: 16px;
    }
    * { font-family: 'Spoqa Han Sans', 'Spoqa Han Sans JP', 'Sans-serif'; }
    @import 'my/base';
    @import 'my/width';
    @import 'my/height';
    @import 'my/position';
    @import 'my/margin';
    @import 'my/padding';
    @import 'my/color';

    @import 'my/input';
    @import 'my/button';
    @import 'my/border';
    @import 'my/style';
    @import 'my/clr';

    @import 'home/Footer';
    @import 'home/Header.scss';
    @import 'home/History.scss';
    @import 'home/Home.scss';
    @import 'home/Main.scss';
    @import 'home/Media.scss';
    @import 'home/Members.scss';
    @import 'home/Partners.scss';
    @import 'home/Value.scss';

    .cursorPointer {
        cursor: pointer;
    }
}