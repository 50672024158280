#partners {
    .partners-container {
        position:relative;
        width:100%;
        height:100%;
        background:#212529;
        font-family:Spoqa Han Sans;
        letter-spacing:-0.25px;
        padding-top:8%;
        padding-bottom:0px;
        @media (min-width:1920px){padding-top:8%;}
        @media (min-width:1280px) and (max-width:1919px){padding-top:8%;}
        @media (min-width:768px) and (max-width:1279px){padding-top:15%;}
        @media (max-width:767px){padding-top:15%; padding-bottom:15%;}
    }

    .title-container {
        display:flex;
        flex-direction:column;
        align-items:center;
        margin-top:15%;
        padding:0px 10%;
        @media (min-width:1920px){margin-top:10%; padding:0px;}
        @media (min-width:1280px) and (max-width:1919px){margin-top:10%; padding:0px;}
        @media (min-width:768px) and (max-width:1279px){margin-top:15%; padding:0px;}
        @media (max-width:767px){margin-top:15%; padding:0px 10%;}
    }

    .title-style {
        width:100%;
        font-weight:bold;
        word-break:keep-all;
        font-size:28px;
        @media (min-width:1920px){font-size:48px;}
        @media (min-width:768px) and (max-width:1919px){font-size:48px;}
        @media (max-width:767px){font-size:28px;}
    }

    .logo-container {
        width:100%;
        display:flex;
        flex-direction:column;
        align-items:flex-start;
        flex-wrap:wrap;
        padding:0px;
        margin-top:5%;
        @media (min-width:1920px){width:100%; display:flex; flex-direction:row; justify-content:flex-start; align-items:center; padding:0px 8%; margin-top:5%;}
        @media (min-width:1280px) and (max-width:1919px){width:100%; display:flex; flex-direction:row; justify-content:flex-start; align-items:center; padding:0px 8%; margin-top:5%;}
        @media (min-width:768px) and (max-width:1279px){width:100%; display:flex; flex-direction:row; justify-content:flex-start; align-items:center; padding:0px 16%; margin-top:15%;}
        @media (max-width:767px){width:100%; display:flex; flex-direction:column; align-items:center; padding:0px; margin-top:5%;}
    }

    .item-margin {
        width:191px;
        margin:10px 20px;
        display:flex;
        justify-content:center; 
        align-items:center;
        @media (min-width:1920px){margin:15px 20px;}
        @media (min-width:1280px) and (max-width:1919px){margin:15px 20px;}
        @media (min-width:768px) and (max-width:1279px){margin:30px 20px;}
        @media (max-width:767px){margin:25px 20px;}
    }
}
