#value {
    .value-container {
        position:relative;
        width:100%;
        height:100%;
        background:#212529;
        font-family:Spoqa Han Sans;
        letter-spacing:-0.25px;
        padding:15% 0px 20% 0px;
        @media (min-width:1920px){padding:8% 10% 0px 10%;}
        @media (min-width:1280px) and (max-width:1919px){padding:8% 10% 0px 10%;}
        @media (min-width:768px) and (max-width:1279px){padding:25% 15% 0px 15%;}
        @media (max-width:767px){padding:15% 0px 20% 0px;}
    }

    .title-margin {
        text-align:center;
        margin-bottom:5%;
        @media (min-width:1920px){margin-bottom:5%;}
        @media (min-width:1280px) and (max-width:1919px){margin-bottom:5%;}
        @media (min-width:768px) and (max-width:1279px){margin-bottom:20%;}
        @media (max-width:767px){margin-bottom:5%;}
    }

    .title-style {
        width:100%;
        font-weight:bold;
        word-break:keep-all;
        font-size:28px;
        @media (min-width:1920px){font-size:48px;}
        @media (min-width:768px) and (max-width:1919px){font-size:48px;}
        @media (max-width:767px){font-size:28px;}
    }

    .sub-text-style {
        width:100%;
        font-size:16px;
        word-break:keep-all;
        margin-top:5%;
        @media (min-width:1920px){margin-top:2%;}
        @media (min-width:1280px) and (max-width:1919px){margin-top:2%;}
        @media (min-width:768px) and (max-width:1279px){margin-top:7%;}
        @media (max-width:767px){margin-top:5%;}
    }

    .items-container {
        width:100%;
        display:flex;
        flex-direction:column;
        align-items:center;
        @media (min-width:1920px){width:100%; display:flex; flex-direction:row; justify-content:space-between; align-items:center; padding:0px 10%;}
        @media (min-width:1280px) and (max-width:1919px){width:100%; display:flex; flex-direction:row; justify-content:space-between; align-items:center; padding:0px 10%;}
        @media (min-width:768px) and (max-width:1279px){width:100%; display:flex; flex-direction:row; justify-content:space-between; align-items:center; padding:0px 1%;}
        @media (max-width:767px){width:100%; display:flex; flex-direction:column; align-items:'center';}
    }

    .items-margin {
        margin:0px;
        @media (max-width:767px){margin:20px 0px;}
    }

    .point-item {
        width:145px;
        height:35px;
        border-radius:50px;
        display:flex;
        justify-content:center;
        align-items:center;
    }

    .point-desc {
        font-weight:bold;
        font-size:16px;
        color:#212529;
    }

    .point-title {
        padding-top:7%;
        font-weight:bold;
        font-size:16px;
    }
    
    .bar-size {
        width:70.3px;
        height:133.25px;
        @media (min-width:1920px){width:170px; height:320px;}
        @media (min-width:1280px) and (max-width:1919px){width:170px; height:320px;}
        @media (min-width:768px) and (max-width:1279px){width:106px; height:198px;}
        @media (max-width:767px){width:70.3px; height:133.25px;}
    }
    
    .roundedBoxSize {
        width:70.3px;
        height:133.25px;
        @media (min-width:1920px){width:170px; height:320px;}
        @media (min-width:1280px) and (max-width:1919px){width:170px; height:320px;}
        @media (min-width:768px) and (max-width:1279px){width:106px; height:198px;}
        @media (max-width:767px){width:70.3px; height:133.25px;}
    }
}