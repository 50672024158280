.clr-control-label {
    font-size: 0.875rem;
}

.clr-form-horizontal .clr-form-control>.clr-control-label, .clr-form-horizontal .clr-form-control>.clr-form-control.clr-form-control-multi .clr-control-label {
    width: 16rem;
}

.clr-input {
    font-size: 0.875rem;
    max-height: 1.25rem;
}

input[type=date], input[type=datetime-local], input[type=email], input[type=number], input[type=password], input[type=tel], input[type=text], input[type=time], input[type=url] {
    height: 1.25rem;
    padding: 0 0.5rem;
}

button, input, optgroup, select, textarea {
    font-size: 0.875rem;
}

.btn {
    padding: 0.25rem 0.75rem;
    font-size: 0.875rem;
    height: 1.75rem;
}

.header .branding .title, header .branding .title {
    font-size: 0.875rem;
}

.title {
    font-size: 0.875rem;
}

.table th {
    font-size: 0.75rem;
}

.table td {
    font-size: 0.75rem;
}

.content-container {
    font-size: 0.875rem;
}