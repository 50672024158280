//.my-btn {
//  color: #737373;
//  border-color: #737373;
//}

.my-btn-link {
  //display: inline-block;
  //border: none;
  //padding: 1rem 2rem;
  //margin: 0;
  //text-decoration: none;
  //background: #0069ed;
  //color: #ffffff;
  //font-family: sans-serif;
  //font-size: 1rem;
  cursor: pointer;
  //text-align: center;
  //transition: background 250ms ease-in-out,
  //transform 150ms ease;
  //-webkit-appearance: none;
  //-moz-appearance: none;

  border-color:transparent;
  background-color:transparent;
  //color:#0079b8
}

.my-btn-link:visited {
  color:#565656;
  border-color:transparent;
  background-color:transparent;
}

.my-btn-link:hover{
  color:#565656;
  text-decoration:underline;
  border-color:transparent;
  background-color:transparent;
}

.my-btn-link:focus {
  outline:0;
}

.my-btn-link:active {
  box-shadow:0 0 0 0 transparent inset
}
