#main {
    .main-container {
        position:relative;
        width:100%;
        height:100%;
        background:#212529;
        font-family:Spoqa Han Sans;
        letter-spacing:-0.25px;
    }

    .contents-container {
        background:#212529;
        color:white;
        width:100%;
        height:100vh;
        display:flex;
        flex-direction:column;
        justify-content:space-between;
        align-items:center;
        padding:40% 10% 0px 10%;
        @media (min-width:1920px){padding:12% 10% 0px 10%;}
        @media (min-width:1280px) and (max-width:1919px){padding:15% 10% 0px 10%;}
        @media (min-width:1203px) and (max-width:1279px){padding:300px 25% 0px 25%;}
        @media (min-width:907px) and (max-width:1279px){padding:300px 20% 0px 20%;}
        @media (min-width:768px) and (max-width:906px){padding:300px 10% 0px 10%;}
        @media (max-width:767px){padding:40% 5% 0px 5%; min-height:700px;}
        @media (min-height:371px) and (max-height:580px){padding:6% 5% 0px 5%}
        @media (max-height:370px){padding:65px 0px 0px 0px;}
        // @media (min-height:316px) and (max-height:600px){padding:10% 30% 0px 30%;}
        // @media (max-height:315px){padding:65px 0px 0px 0px;}
    }

    .title-fontSize {
        text-align:center;
        font-weight:bold;
        word-break:keep-all;
        width:70%; 
        font-size:28px;
        @media (min-width:1920px){width:70%; font-size:48px;}
        @media (min-width:1280px) and (max-width:1919px){width:70%; font-size:48px;}
        @media (min-width:768px) and (max-width:1279px){width:100%; font-size:48px;}
        @media (max-width:767px){width:70%; font-size:28px;}
        @media (max-height:630px){width:70%; font-size:48px;}
        // @media (min-height:316px) and (max-height:600px){width:100%; font-size:48px;}
        // @media (max-height:315px){width:70%; font-size:28px;}
    }

    .main-logo {
        padding-top:15%;
        width:98px;
        height:43px;
        @media (min-width:1920px){padding-top:7%; width:300px; height:132.41px;}
        @media (min-width:1280px) and (max-width:1919px){padding-top:7%; width:172px; height:76px;}
        @media (min-width:1120px) and (max-width:1279px){padding-top:7%; width:172px; height:76px;}
        @media (min-width:768px) and (max-width:1279px){padding-top:10%; width:172px; height:76px;}
        @media (max-width:767px){padding-top:15%; width:98px; height:43px;}
        @media (max-height:630px){padding-top:3%; width:98px; height:43px;}
        // @media (min-height:316px) and (max-height:600px){padding-top:5%; width:172px; height:76px;}
        // @media (max-height:315px){padding-top:3%; width:98px; height:43px;}
    }

    .down-arrow {
        font-size:25px;
        margin-bottom:3%;
        opacity:1;
        @media (min-width:1920px){opacity:1;}
        @media (min-width:1280px) and (max-width:1919px){opacity:1;}
        @media (max-width:1279px){opacity:0;}
        @media (max-height:630px){opacity:0;}
    }

    .main-img {
        width:80%;
        max-width:1000px;
        position:absolute;
        bottom:0;
        left:0;
        z-index:0;
        @media (min-width:1920px){width:50%; bottom:0;}
        @media (min-width:1280px) and (max-width:1919px){width:50%; bottom:0;}
        @media (min-width:768px) and (max-width:1279px){width:65%; bottom:0;}
        @media (max-width:767px){width:80%; bottom:0;}
        @media (max-height:580px){width:35%; bottom:0;}
        // @media (min-height:316px) and (max-height:600px){width:35%; bottom:0;}
        // @media (max-height:315px){width:25%; bottom:0;}
    }
}