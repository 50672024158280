.padding-5 { padding: 5px; }
.padding-10 { padding: 10px; }
.padding-15 { padding: 15px; }
.padding-20 { padding: 20px; }
.padding-25 { padding: 25px; }
.padding-30 { padding: 30px; }
.padding-35 { padding: 35px; }
.padding-40 { padding: 40px; }
.padding-45 { padding: 45px; }
.padding-50 { padding: 50px; }
.padding-55 { padding: 55px; }
.padding-60 { padding: 60px; }
.padding-65 { padding: 65px; }
.padding-70 { padding: 70px; }
.padding-75 { padding: 75px; }
.padding-80 { padding: 80px; }
.padding-85 { padding: 85px; }
.padding-90 { padding: 90px; }
.padding-95 { padding: 95px; }
.padding-100 { padding: 100px; }

.padding-left-5 { padding-left: 5px; }
.padding-left-10 { padding-left: 10px; }
.padding-left-15 { padding-left: 15px; }
.padding-left-20 { padding-left: 20px; }
.padding-left-25 { padding-left: 25px; }
.padding-left-30 { padding-left: 30px; }
.padding-left-35 { padding-left: 35px; }
.padding-left-40 { padding-left: 40px; }
.padding-left-45 { padding-left: 45px; }
.padding-left-50 { padding-left: 50px; }
.padding-left-55 { padding-left: 55px; }
.padding-left-60 { padding-left: 60px; }
.padding-left-65 { padding-left: 65px; }
.padding-left-70 { padding-left: 70px; }
.padding-left-75 { padding-left: 75px; }
.padding-left-80 { padding-left: 80px; }
.padding-left-85 { padding-left: 85px; }
.padding-left-90 { padding-left: 90px; }
.padding-left-95 { padding-left: 95px; }
.padding-left-100 { padding-left: 100px; }

.padding-right-5 { padding-right: 5px; }
.padding-right-10 { padding-right: 10px; }
.padding-right-15 { padding-right: 15px; }
.padding-right-20 { padding-right: 20px; }
.padding-right-25 { padding-right: 25px; }
.padding-right-30 { padding-right: 30px; }
.padding-right-35 { padding-right: 35px; }
.padding-right-40 { padding-right: 40px; }
.padding-right-45 { padding-right: 45px; }
.padding-right-50 { padding-right: 50px; }
.padding-right-55 { padding-right: 55px; }
.padding-right-60 { padding-right: 60px; }
.padding-right-65 { padding-right: 65px; }
.padding-right-70 { padding-right: 70px; }
.padding-right-75 { padding-right: 75px; }
.padding-right-80 { padding-right: 80px; }
.padding-right-85 { padding-right: 85px; }
.padding-right-90 { padding-right: 90px; }
.padding-right-95 { padding-right: 95px; }
.padding-right-100 { padding-right: 100px; }

.padding-top-5 { padding-top: 5px; }
.padding-top-10 { padding-top: 10px; }
.padding-top-15 { padding-top: 15px; }
.padding-top-20 { padding-top: 20px; }
.padding-top-25 { padding-top: 25px; }
.padding-top-30 { padding-top: 30px; }
.padding-top-35 { padding-top: 35px; }
.padding-top-40 { padding-top: 40px; }
.padding-top-45 { padding-top: 45px; }
.padding-top-50 { padding-top: 50px; }
.padding-top-55 { padding-top: 55px; }
.padding-top-60 { padding-top: 60px; }
.padding-top-65 { padding-top: 65px; }
.padding-top-70 { padding-top: 70px; }
.padding-top-75 { padding-top: 75px; }
.padding-top-80 { padding-top: 80px; }
.padding-top-85 { padding-top: 85px; }
.padding-top-90 { padding-top: 90px; }
.padding-top-95 { padding-top: 95px; }
.padding-top-100 { padding-top: 100px; }

.padding-bottom-5 { padding-bottom: 5px; }
.padding-bottom-10 { padding-bottom: 10px; }
.padding-bottom-15 { padding-bottom: 15px; }
.padding-bottom-20 { padding-bottom: 20px; }
.padding-bottom-25 { padding-bottom: 25px; }
.padding-bottom-30 { padding-bottom: 30px; }
.padding-bottom-35 { padding-bottom: 35px; }
.padding-bottom-40 { padding-bottom: 40px; }
.padding-bottom-45 { padding-bottom: 45px; }
.padding-bottom-50 { padding-bottom: 50px; }
.padding-bottom-55 { padding-bottom: 55px; }
.padding-bottom-60 { padding-bottom: 60px; }
.padding-bottom-65 { padding-bottom: 65px; }
.padding-bottom-70 { padding-bottom: 70px; }
.padding-bottom-75 { padding-bottom: 75px; }
.padding-bottom-80 { padding-bottom: 80px; }
.padding-bottom-85 { padding-bottom: 85px; }
.padding-bottom-90 { padding-bottom: 90px; }
.padding-bottom-95 { padding-bottom: 95px; }
.padding-bottom-100 { padding-bottom: 100px; }
