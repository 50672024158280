.margin-5 { margin: 5px; }
.margin-10 { margin: 10px; }
.margin-15 { margin: 15px; }
.margin-20 { margin: 20px; }
.margin-25 { margin: 25px; }
.margin-30 { margin: 30px; }
.margin-35 { margin: 35px; }
.margin-40 { margin: 40px; }
.margin-45 { margin: 45px; }
.margin-50 { margin: 50px; }
.margin-55 { margin: 55px; }
.margin-60 { margin: 60px; }
.margin-65 { margin: 65px; }
.margin-70 { margin: 70px; }
.margin-75 { margin: 75px; }
.margin-80 { margin: 80px; }
.margin-85 { margin: 85px; }
.margin-90 { margin: 90px; }
.margin-95 { margin: 95px; }
.margin-100 { margin: 100px; }

.margin-left-5 { margin-left: 5px; }
.margin-left-10 { margin-left: 10px; }
.margin-left-15 { margin-left: 15px; }
.margin-left-20 { margin-left: 20px; }
.margin-left-25 { margin-left: 25px; }
.margin-left-30 { margin-left: 30px; }
.margin-left-35 { margin-left: 35px; }
.margin-left-40 { margin-left: 40px; }
.margin-left-45 { margin-left: 45px; }
.margin-left-50 { margin-left: 50px; }
.margin-left-55 { margin-left: 55px; }
.margin-left-60 { margin-left: 60px; }
.margin-left-65 { margin-left: 65px; }
.margin-left-70 { margin-left: 70px; }
.margin-left-75 { margin-left: 75px; }
.margin-left-80 { margin-left: 80px; }
.margin-left-85 { margin-left: 85px; }
.margin-left-90 { margin-left: 90px; }
.margin-left-95 { margin-left: 95px; }
.margin-left-100 { margin-left: 100px; }
.margin-left-190 { margin-left: 190px; }

.margin-right-5 { margin-right: 5px; }
.margin-right-10 { margin-right: 10px; }
.margin-right-15 { margin-right: 15px; }
.margin-right-20 { margin-right: 20px; }
.margin-right-25 { margin-right: 25px; }
.margin-right-30 { margin-right: 30px; }
.margin-right-35 { margin-right: 35px; }
.margin-right-40 { margin-right: 40px; }
.margin-right-45 { margin-right: 45px; }
.margin-right-50 { margin-right: 50px; }
.margin-right-55 { margin-right: 55px; }
.margin-right-60 { margin-right: 60px; }
.margin-right-65 { margin-right: 65px; }
.margin-right-70 { margin-right: 70px; }
.margin-right-75 { margin-right: 75px; }
.margin-right-80 { margin-right: 80px; }
.margin-right-85 { margin-right: 85px; }
.margin-right-90 { margin-right: 90px; }
.margin-right-95 { margin-right: 95px; }
.margin-right-100 { margin-right: 100px; }

.margin-top-5 { margin-top: 5px; }
.margin-top-10 { margin-top: 10px; }
.margin-top-15 { margin-top: 15px; }
.margin-top-20 { margin-top: 20px; }
.margin-top-25 { margin-top: 25px; }
.margin-top-30 { margin-top: 30px; }
.margin-top-35 { margin-top: 35px; }
.margin-top-40 { margin-top: 40px; }
.margin-top-45 { margin-top: 45px; }
.margin-top-50 { margin-top: 50px; }
.margin-top-55 { margin-top: 55px; }
.margin-top-60 { margin-top: 60px; }
.margin-top-65 { margin-top: 65px; }
.margin-top-70 { margin-top: 70px; }
.margin-top-75 { margin-top: 75px; }
.margin-top-80 { margin-top: 80px; }
.margin-top-85 { margin-top: 85px; }
.margin-top-90 { margin-top: 90px; }
.margin-top-95 { margin-top: 95px; }
.margin-top-100 { margin-top: 100px; }

.margin-bottom-5 { margin-bottom: 5px; }
.margin-bottom-10 { margin-bottom: 10px; }
.margin-bottom-15 { margin-bottom: 15px; }
.margin-bottom-20 { margin-bottom: 20px; }
.margin-bottom-25 { margin-bottom: 25px; }
.margin-bottom-30 { margin-bottom: 30px; }
.margin-bottom-35 { margin-bottom: 35px; }
.margin-bottom-40 { margin-bottom: 40px; }
.margin-bottom-45 { margin-bottom: 45px; }
.margin-bottom-50 { margin-bottom: 50px; }
.margin-bottom-55 { margin-bottom: 55px; }
.margin-bottom-60 { margin-bottom: 60px; }
.margin-bottom-65 { margin-bottom: 65px; }
.margin-bottom-70 { margin-bottom: 70px; }
.margin-bottom-75 { margin-bottom: 75px; }
.margin-bottom-80 { margin-bottom: 80px; }
.margin-bottom-85 { margin-bottom: 85px; }
.margin-bottom-90 { margin-bottom: 90px; }
.margin-bottom-95 { margin-bottom: 95px; }
.margin-bottom-100 { margin-bottom: 100px; }
