#media {
    .media-container {
        position:relative;
        width:100%;
        height:100%;
        background:#212529;
        font-family:Spoqa Han Sans;
        letter-spacing:-0.25px;
        padding-top:8%;
        padding-bottom:50px;
        @media (min-width:1920px){padding-top:8%;}
        @media (min-width:1280px) and (max-width:1919px){padding-top:8%;}
        @media (min-width:768px) and (max-width:1279px){padding-top:15%;}
        @media (max-width:767px){padding-top:15%;}
    }

    .item-container {
        width:100%;
        display:flex;
        flex-direction:column;
        align-items:flex-start;
        flex-wrap:wrap;
        padding:0px;
        margin-top:15px;
        @media (min-width:1920px){width:100%; display:flex; flex-direction:row; justify-content:flex-start; align-items:center; padding:0px 5%; margin-top:70px;}
        @media (min-width:1280px) and (max-width:1919px){width:100%; display:flex; flex-direction:row; justify-content:flex-start; align-items:center; padding:0px 5%; margin-top:70px;}
        @media (min-width:768px) and (max-width:1279px){width:100%; display:flex; flex-direction:row; justify-content:flex-start; align-items:center; padding:0px 18px; margin-top:50px;}
        @media (max-width:767px){width:100%; display:flex; flex-direction:column; align-items:center; padding:0px; margin-top:20px;}
    }

    .media-item-container {
        display: block;
        overflow:hidden;
    }

    .media-img-container {
        display: inline-block;
        overflow: hidden;            /* clip the excess when child gets bigger than parent */
    }

    .media-img {
        display: block;
        transition:transform .4s ease-in-out;   /* smoother zoom */
    }
    
    .media-item-container:hover .media-img {
        transform: scale(1.1);
        transform-origin: 50% 50%;
    }
}