#home {
    section {
        padding: 0 !important;
    }
    background-color: #212529;
    .fixed-left {
        left:0;
        top:0;
        position: fixed;
    }
    .relative-bottom {
        position: absolute;
        bottom: 0;
        left: 0;
    }
    .right-content {
        background-color: white;
    }
}

#service {
    .service-bar-style {
        width:100%;
        height:8px;
    }

    .service-bar-style-specific {
        margin-top:50px;
        @media (min-width:1920px){margin-top:100px;}
        @media (min-width:1280px) and (max-width:1919px){margin-top:50px;}
        @media (min-width:768px) and (max-width:1279px){margin-top:50px;}
        @media (max-width:767px){margin-top:50px;}
    }

    .desktop-service-title-container {
        background:#101317;
        display:flex;
        flex-direction:column;
        justify-content:center;
        align-items:flex-end;
        color:white;
        font-size:16px;
    }

    .desktop-service-id {
        font-size:80px;
        // @media (max-height:450px){font-size:40px;}
    }

    .desktop-service-title {
        font-size:20px;
        font-weight:bold;
        // @media (max-height:450px){font-size:16px;}
    }

    .desktop-service-detail {
        width:100%;
        max-width:300px;
        font-size:16px;
        word-break:keep-all;
        padding-top:20px;
        // @media (max-height:450px){font-size:12px; padding-top:15px;}
    }

    .service-title {
        height:100vh;
        padding:40% 0px 45% 10%;
        color:white;
        font-size:16px;
        @media (min-width:768px) and (max-width:1279px){height:640px; padding:18% 18% 20% 18%;}       
        @media (max-width:767px){height:100vh; padding:40% 0px 45% 10%;}
    }

    .index-margin {
        margin-bottom:10%;
        @media (min-width:768px) and (max-width:1279px){margin-bottom:5%;}
        @media (max-width:767px){margin-bottom:10%;}
    }

    .title-font {
        font-size:28px;
        font-weight:bold;
        @media (min-width:1920px){font-size:48px;}
        @media (min-width:768px) and (max-width:1919px){font-size:48px;}
        @media (max-width:767px){font-size:28px;}
    }

    .service-contents-text-container {
        width:100%;
        display:flex;
        flex-direction:column;
        justify-content:center;
        align-items:flex-start;
        padding:0px 5%;
        @media (min-width:1920px){padding:0px;}
        @media (min-width:1280px) and (max-width:1919px){padding:0px;}
        @media (min-width:768px) and (max-width:1279px){padding:0px 28%;}
        @media (max-width:767px){padding:0px 5%;}
        @media (max-height:600px){padding:0px 0px 0px 7%;}
    }

    .btn-style {
        width:205px;
        height:48px;
        border-radius:50px;
        display:flex;
        justify-content:center;
        align-items:center;
        cursor:pointer;
        font-size:16px;
        font-weight:bold;
        margin-top:25%;
        @media (min-width:1920px){margin-top:45px;}
        @media (min-width:1280px) and (max-width:1919px){margin-top:45px;}
        @media (min-width:768px) and (max-width:1279px){margin-top:10%;}
        @media (max-width:767px){margin-top:25%;}
    }

    .btn-style-two {
        // width:225px;
        width:238px;
        height:48px;
        border-radius:50px;
        display:flex;
        justify-content:center;
        align-items:center;
        cursor:pointer;
        font-size:16px;
        font-weight:bold;
        margin-top:5%;
        @media (min-width:1920px){margin-top:5%;}
        @media (min-width:1280px) and (max-width:1919px){margin-top:5%;}
        @media (min-width:768px) and (max-width:1279px){margin-top:2%;}
        @media (max-width:767px){margin-top:5%;}
    }

    .service-contents {
        background:white;
        padding-bottom:10%;
    }

    .service-contents-container {
        width:100%;
        display:flex;
        flex-direction:column;
        flex-grow:1;
        justify-content:center;
        align-items:center;
        margin:10% 0px;
        @media (min-width:1920px){margin:0px;}
        @media (min-width:1280px) and (max-width:1919px){margin:0px;}
        @media (min-width:768px) and (max-width:1279px){margin:20% 0px;}
        @media (max-width:767px){margin:20% 0px;}
    }

    .service-contents-desc-container {
        display:flex;
        flex-direction:column;
        @media (max-height:600px){flex-direction:row; align-items:center;}
    }

    .img-size {
        width:100%;
        max-width:320px;
        height:auto;
        max-height:100vh;
        @media (min-width:1920px){max-width:375px;}
        @media (min-width:768px) and (max-width:1919px){max-width:375px;}
        @media (max-width:767px){max-width:320px;}
    }

    .btn-container {
        width:100%;
        max-width:320px;
        display:flex;
        justify-content:center;
        align-items:center;
        margin-top:10px;
        @media (min-width:1920px){max-width:375px;}
        @media (min-width:768px) and (max-width:1919px){max-width:375px;}
        @media (max-width:767px){max-width:320px;} 
    }

    .img-change-btn {
        width:8px;
        height:8px;
        border-radius:50%;
        border:1px solid #FF916E;
        background:white;
        cursor:pointer;
        margin:0px 5px;
    }
}

.onlyMobileContainer {
    height:100%;
    @media (min-width:1920px){height:100vh;}
    @media (min-width:1280px) and (max-width:1919px){height:100vh;}
    @media (min-width:768px) and (max-width:1279px){height:100vh;}
    @media (max-width:767px){height:100%;}
}