#header-wrap {
    .nav-hover-item {
        color:white;
        font-weight:normal;
        &:hover {
            color:#FF916E !important;
            font-weight:bold !important;
        
        }
        &:active {
            color:#FF916E !important;
            font-weight:bold !important;
        }
    }

    .header-btn {
        background:transparent;
        border:transparent;
    }

}
